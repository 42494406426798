import {Badge, Card, Divider, Flex, Heading, Text, useTheme} from "@aws-amplify/ui-react";
import React from "react";
import {ItemBadge} from "./Indicators";
import Tooltip from "rc-tooltip";

//
// The generic cards used to display fields, with a slot for a control button
//

// @ts-ignore
export const GenericFieldCard = ({field, children}) => {
    // Base formatting
    const { tokens } = useTheme();
    let background_color = tokens.colors.neutral["10"]

    let title = field.tdFieldname
    let text = field.tdsaDescription
    let field_text = field.id
    let spec_builder_name = field.SpecbuilderName


    if (field.allowedValues){
        background_color = tokens.colors.brand.primary["20"]
        title = "Constraint on " + field.fieldName
        text = "Allowed values: "  + field.allowedValues.join(", ")
    }

    let sensitivity = 'Sensitive ('+ field.sensitivity +')'

    return (
        <Card
            backgroundColor={background_color}
            key={field.id}
            width={"100%"}
            variation={"elevated"}>
            <Flex direction="row" justifyContent={"space-between"} alignItems="flex-start" alignContent={"space-between"} width={"100%"}>
                <Flex
                    direction="column"
                    alignItems="flex-start"
                    gap={tokens.space.xs}
                    width={"100%"}>
                    <Tooltip placement={"top"} overlay={`Spec builder name: ${spec_builder_name}`}>
                        <Heading level={5}>
                            {title}
                        </Heading>
                    </Tooltip>

                    <Flex direction="row" alignItems="center" gap={tokens.space.xs}>
                        <Tooltip placement={"bottom"} overlay="TD Fieldname and Unique ID">
                            <Badge size="small" style={{ fontSize: '0.6em' }}> TD: {field_text} </Badge>
                        </Tooltip>
                    </Flex>

                    <Text variation="info" as="span">
                        {text}
                    </Text>



                    <Flex>
                        {field.sensitivity > 0 &&
                            <ItemBadge
                                label={sensitivity}
                                variation={"warning"}
                                tooltip={"This field has a sensitivity score. Orders with high sensitivity scores need to be justified."}/>
                        }
                        {field.specialCategory &&
                            <ItemBadge
                                label={'Special category'}
                                variation={"error"}
                                tooltip={"Special category fields contain sensitive personal information, and cannot be combined or cross-tabulated with other special category fields."}/>
                        }
                        {field.freeText &&
                            <ItemBadge
                                label={'Free text'}
                                variation={'info'}
                                tooltip={'Free text fields can contain a wide range of values, making them hard to analyse.'}/>
                        }
                        {field.menu &&
                            <ItemBadge
                                label={'Menu'}
                                variation={'error'}
                                tooltip={'This field is for display purposes only and is not available for actual use.'}/>
                        }
                    </Flex>
                </Flex>
                <Flex alignContent={"flex-end"}>
                    {children}
                </Flex>
            </Flex>
        </Card>
    )
}

// @ts-ignore
export const CompactGenericFieldCard = ({field, children}) => {
    // Base formatting
    const { tokens } = useTheme();
    let background_color = tokens.colors.neutral["10"]

    let title = field.tdFieldname
    //let text = field.description

    if (field.allowedValues){
        background_color = tokens.colors.brand.primary["20"]
        title = "Constraint on " + field.tdFieldname
        //text = "Allowed values: "  + field.allowedValues.join(", ")
    }

    return (
        <Card
            backgroundColor={background_color}
            key={field.id}
            width={"100%"}>
            <Flex direction="row"
                  alignItems="center"
                  gap={tokens.space.xs}
            >
                <Flex
                    direction="row"
                    alignItems="center"
                    width={"100%"}
                >
                    <Heading fontSize={"0.8rem"}>{title}</Heading>
                    {field.sensitive &&
                    <Badge size="small" variation="info">
                        Sensitive
                    </Badge>
                    }
                </Flex>
                <Flex
                      alignItems="center"
                      gap={tokens.space.xs}>
                    {children}
                </Flex>
            </Flex>
            <Divider/>
        </Card>
    )
}